import {
    getStorageDirectFileUploadEndpoint,
    StorageDirectUploadFileRequest,
    StorageDirectUploadFileResponse,
} from '@cp-shared-10/apis';
import { AxiosInstance } from 'axios';

type FileId = string;

export const readFile = (file: File): Promise<string | undefined> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string | undefined);
        reader.onerror = error => reject(error);
    });

export const fileToBase64 = async (file: File): Promise<string | undefined> => {
    const data = await readFile(file);
    return data?.split('base64,')[1];
};

export const uploadFilesDirectToMediaStorage = (files: File[], CpDataApi: AxiosInstance): Promise<FileId[]> =>
    Promise.all(
        files.map(async file => {
            const base64 = await fileToBase64(file);
            if (!base64) return '';

            const data: StorageDirectUploadFileRequest = {
                name: file.name,
                mimeType: file.type,
                base64,
            };

            const response = await CpDataApi.post<StorageDirectUploadFileResponse>(
                getStorageDirectFileUploadEndpoint(),
                data,
            );
            return response.data.id;
        }),
    );
